.table {
    border: 1px solid black;
    text-align: center;
    vertical-align: center;
    width: 100%;
}

.crs-head {
    background-color: var(--base-theme-selected-highlight-blue-color);
}

.sub-head {
    background-color: lightgray;
}