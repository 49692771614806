.checkout-form-container {
  display: grid;
  grid-template-columns: 280px 1fr;
  grid-gap: 16px;
  padding: 12px;
  background-color: var(--base-background-highlight-color);
  border-radius: var(--border-radius-6);
  min-height: 480px;
}

.crs-checkout {
  display: grid;
  grid-template-rows: auto auto;
  height: 200px;
  background: var(--base-background-highlight-color);
  justify-items: center;
  justify-content: center;
  position: relative;
}

.checkout-model {
  display: grid;
  justify-items: center;
  height: 100vh;
  background-color: var(--base-model-background-color);
  z-index: var(--z-index-5);
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
}

.crs-plan {
  border-bottom: var(--border-bottom-line);
  width: 100%;
  padding-bottom: 20px;
  justify-items: center;
}

.dismiss-button {
  color: var(--color-white);
  opacity: 1;
  cursor: pointer;
}

.plan-crs-name {
  font-weight: var(--font-weight-bold);
  margin-top: 20px;
}

.pyment-plan {
  background-color: var(--base-background-color);
  border: var(--border-bottom-line);
  width: 200px;
  height: 180px;
  box-shadow: rgb(0 0 0 / 14%) 0px 4px 24px 0px;
  margin-top: 20px;
}

.pyment-plan .title {
  padding: 25px 0 0 24px;
  font-weight: var(--font-weight-bold);
}

.pyment-plan .price {
  background-color: #e1dfdf;
  width: max-content;
  min-width: 150px;
  height: 40px;
  margin: 25px 0 0 24px;
  padding: 12px 0;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-13);
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  border-radius: 4px;
}