.tpc-nm .access-icon {
  width: 16px;
  height: 16px;
}

.chapter-header {
  font-weight: var(--font-weight-semibold);
  padding: 6px;
  border-radius: var(--border-radius-6);
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 4px;
}

.chapter-header:hover {
  cursor: pointer;
  background-color: rgb(228 229 227 / .35);
}

.chapter-header img {
  width: 10px;
  height: 10px;
  margin-top: 5px;
}

.tpc-cntr {
  padding-inline-start: 18px;
}

.tpc-nm {
  border-radius: 4px;
  display: grid;
  align-items: center;
  height: 28px;
  grid-template-columns: max-content max-content;
}

.tpc-nm span {
  margin-left: 6px;
  margin-right: 10px;
}

.tpc-nm.selected {
  background-color: var(--base-theme-selected-highlight-blue-color);
}

.tpc-nm.notselected:hover {
  background-color: var(--base-theme-hover-highlight-blue-color);
  cursor: pointer;
}