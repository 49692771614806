.pymnt-Rqrd-img {
  height: 50%
}

.pymnt-Rqrd-cntnr {
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
  row-gap: 30px;
  font-size: var(--font-size-h5);
  font-weight: 600;
  justify-content: center;
}

.subsc-model-cntnr {
  display: grid;
  width: 90%;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fill, 200px);
  justify-content: center;
}