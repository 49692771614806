.model-bg {
  margin: auto;
  display: grid;
  grid-template-rows: 48px 1fr;
}

.model-bg form {
  min-width: 478px;
}

.dismiss-button {
  color: var(--color-white);
  opacity: 1;
  cursor: pointer;
  display: grid;
  justify-self: end;
}

.dismiss-button img {
  color: var(--color-white);
}

.menu-item.border {
  border-bottom: var(--border-bottom-line);
}

.menu-item .user-id {
  font-weight: var(--font-weight-semibold);
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-item.option {
  cursor: pointer;
}

.menu-item.option:hover {
  color: var(--base-theme-color-hover-blue);
}

.sign-in-model {
  display: grid;
  justify-items: center;
  height: 100%;
  background-color: var(--base-model-background-color);
  z-index: var(--z-index-5);
  position: absolute;
  width: 100%;
}

.usr-menu {
  position: absolute;
  width: 200px;
  right: 40px;
  box-shadow: rgb(0 0 0 / 14%) 0px 4px 24px 0px;
  background-color: var(--base-background-color);
  border-radius: var(--border-radius-12);
  font-size: var(--font-size-text);
  font-weight: 400;
  color: var(--base-font-color-light);
  margin-top: 4px;
  z-index: var(--z-index-10);
}

.usr-menu .menu-item {
  padding: 12px;
}

.user-img {
  display: flex;
  opacity: var(--opacity-6);
  height: 28px;
  cursor: pointer;
}

.user-img:hover img {
  height: 30px;
}