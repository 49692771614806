.sub-model {
    background-color: var(--base-background-color);
    border: var(--border-bottom-line);
    width: 200px;
    height: 230px;
    box-shadow: rgb(0 0 0 / 14%) 0px 4px 24px 0px;
}

.sub-model:hover {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 24px 0px;
    cursor: pointer;
}

.sub-model .title {
    padding: 25px 0 0 24px;
    font-weight: var(--font-weight-bold);
    width: 80%;
}

.sub-model .price {
    background-color: #e1dfdf;
    width: max-content;
    min-width: 150px;
    height: 40px;
    margin: 25px 0 0 24px;
    padding: 12px 0;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-13);
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
    border-radius: 4px;
}

.subscribe {
    font-weight: var(--font-weight-bold);
}

.subscribe {
    background: var(--base-theme-color-blue);
    color: #ffffff;
    border-radius: 4px;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: var(--font-weight-bold);
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: 37px;
    text-align: center;
}