.no-money-img {
  height: 35%
}

.no-money-cntnr {
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
  row-gap: 30px;
  font-size: var(--font-size-h5);
  font-weight: var(--font-weight-semibold);
}