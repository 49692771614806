:root {
  /* Colors */
  --base-theme-color-blue: #2990c7;
  --base-theme-color-dark-blue: #0880c0;
  --base-theme-hover-highlight-blue-color: rgb(8 128 192/0.1);
  --base-theme-selected-highlight-blue-color: #0880c080;
  --base-theme-color-hover-blue: var(--base-theme-color-dark-blue);

  --base-theme-color-orange: #f18620;

  --base-background-color: #fefefe;
  --base-background-highlight-color: #f6f6f6;
  --base-background-highlight-color-dark: #e6e4e4;

  --base-model-background-color: rgb(0 0 0/0.7);

  --color-white: #fff;
  --color-black: #000;
  --color-light-grey: #e5e7eb;
  --color-dark-grey: #83868c;

  /* Fonts */
  --base-font-color: #344054;
  --base-font-color-light: #5f6368;

  --font-size-h1: 42px;
  --font-size-h2: 36px;
  --font-size-h3: 28px;
  --font-size-h4: 20px;
  --font-size-h5: 18px;
  --font-size-12: 12px;
  --font-size-13: 13px;
  --font-size-14: 14px;
  --font-size-text: 16px;
  --font-size-subscript: 10px;

  --font-weight-bold: 700;
  --font-weight-semibold: 600;

  /* Border Radius */
  --border-radius-6: 6px;
  --border-radius-12: 12px;
  --border-radius-16: 16px;
  --border-radius-32: 32px;


  /* Divider Line */
  --border-bottom-line: 1px solid var(--color-light-grey);

  /* Subscript */
  --color-text-subscript: var(--base-theme-color-blue);
  --color-background-subscript: var(--color-light-grey);

  /* Opacity */
  --opacity-6: 0.6;
  --opacity-9: 0.9;

  /* Z-Index */
  --z-index-3: 3;
  --z-index-5: 5;
  --z-index-10: 10;

  /* Code block related CSS */
  --code-font-family: "Source Code Pro";

  --code-font-size: var(--font-size-14);
  --code-font-color: rgb(245, 251, 255);
  --code-comment-color: rgb(193, 201, 210);
  --code-keyword-color: rgb(164, 205, 254);
  --code-token-keyword-font-weight: 500;
  --code-function-color: rgb(245, 251, 255);
  --code-punctuation-color: rgb(245, 251, 255);
  --code-background-color: rgb(33, 45, 99);
  --code-operator-color: rgb(245, 251, 255);
  --code-token-number-color: rgb(248, 184, 134);
  --code-token-boolean-color: rgb(127, 211, 237);
  --code-token-boolean-font-weight: 500;
  --code-char-string-color: rgb(133, 217, 150);
  --code-variable-color: #f5fbff;
  --code-border: 1px solid #e0e0e0;

}

a {
  color: var(--base-font-color);
  text-decoration: none;
}

body {
  margin: 0;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue',
    sans-serif;
  color: var(--base-font-color);
  background-color: var(--base-background-color);
  height: 100vh;
  font-style: normal;
}

.button, .nav-text {
  font-size: var(--font-size-text);
}

*, :after, :before {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

h1 {
  font-size: var(--font-size-h1);
  font-weight: var(--font-weight-bold);
}

h2 {
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-semibold);
}

h3 {
  font-size: var(--font-size-h3);
}

h4 {
  font-size: var(--font-size-h4);
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
}

h1, h3 {
  margin-block-start: 0.7em;
  margin-block-end: 0.7em;
}

.message {
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-h5);
  text-align: center;
}