.course-box-dtls {
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 10px;
  justify-items: center;
}

.course-box-dtls .crs-nm {
  font-weight: var(--font-weight-semibold);
  margin-bottom: 20px;
  color: var(--base-font-color-light);
  font-weight: var(--font-weight-bold);
}

.course-box-img {
  width: 100%;
  overflow: hidden;
  height: 180px;
}

.crs-bx-cntr {
  cursor: pointer;
}

.crs-bx-cntr-no-click, .crs-bx-cntr {
  display: grid;
  grid-template-rows: 196px auto;
  height: 320px;
  border-radius: var(--border-radius-12);
  background: var(--base-background-highlight-color);
  box-shadow: rgb(0 0 0 / 14%) 0px 4px 24px 0px;
  justify-items: center;
  position: relative;
}

.crs-bx-cntr-no-click .coming-soon-sbsc {
  font-size: 14px;
}

.crs-bx-cntr-no-click .crs-bx-soon {
  height: 28px;
  margin-top: 20px;
}

.crs-bx-cntr-no-click:hover .coming-soon-sbsc {
  font-size: 15px;
  font-weight: var(--font-weight-bold);
}

.crs-bx-cntr:hover {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 24px 0px;
}

.sbscrbd {
  font-size: var(--font-size-14);
  overflow: hidden;
  background-color: var(--base-theme-color-orange);
  opacity: var(--opacity-9);
  font-weight: var(--font-weight-semibold);
  border-radius: var(--border-radius-6);
  text-align: center;
  color: white;
  padding: 0 5px;
  margin-top: 20px;
}