.courses-container {
  width: 90%;
  margin: -60px auto;
  display: grid;
  grid-gap: 2em;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(250px, 280px));
  padding-bottom: 24px;
}

.courses-container a {
  text-decoration: none;
}

.home-page {
  display: grid;
  grid-template-rows: 280px 1fr;
  overflow-x: hidden;
}

.home-page-top {
  display: grid;
  /* grid-template-columns: 1fr minmax(200px, 300px); */
  padding: 0 40px;
  background-color: var(--base-background-highlight-color);
  border-bottom-right-radius: 40%;
  background: radial-gradient(ellipse at 0% 10%, white 0%, var(--base-background-highlight-color) 50%, var(--base-background-highlight-color-dark) 100%);
}

.home-page-top h1 {
  font-weight: var(--font-weight-bold);
  color: var(--base-font-color)
}

.home-page-top h2 {
  font-weight: var(--font-weight-semibold);
}

.home-page-top h3 {
  font-weight: var(--font-weight-semibold);
}