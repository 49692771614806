.sub-box-dtls {
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 10px;
  justify-items: center;
}

.sub-box-dtls .crs-nm {
  font-weight: var(--font-weight-semibold);
  margin-bottom: 20px;
  color: var(--base-font-color-light);
  font-weight: var(--font-weight-bold);
}

.sub-box-img {
  width: 100%;
  overflow: hidden;
  height: 180px;
}

.sub-bx-cntr {
  display: grid;
  grid-template-rows: auto auto;
  height: 380px;
  border-radius: var(--border-radius-12);
  background: var(--base-background-highlight-color);
  box-shadow: rgb(0 0 0 / 14%) 0px 4px 24px 0px;
  justify-items: center;
  position: relative;
}

.sub-bx-cntr button {
  height: 46px;
  width: auto;
  background-color: var(--base-theme-color-blue);
  color: white;
  display: grid;
  align-items: center;
  margin-top: 20px;
}