.dsply-img {
  height: 270px;
  display: grid;
  justify-items: center;
  overflow: auto
}

.dsply-img img {
  height: 270px;
}

.img-plyr {
  height: 300px;
  width: 100%;
  max-width: 600px;
  border: var(--border-bottom-line);
  border-radius: var(--border-radius-12);
  margin-top: 15px;
  margin-bottom: 15px;
}

.play-pause, .arrow {
  height: 16px;
  cursor: pointer;
}

.plyr-cntrl {
  display: grid;
  color: white;
  background-color: black;
  grid-template-columns: 1fr auto;
  justify-items: center;
  padding: 3px 0;
}

.plyr-cntrl .index {
  justify-self: end;
  margin-right: 6px;
}

.plyr-cntrl .mdl-cntl {
  display: grid;
  width: 100px;
  grid-template-columns: auto auto auto;
  align-content: center;
  justify-items: center;
}