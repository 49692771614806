.carousel {
  overflow: scroll;
  position: relative;
  margin: auto;
}

.carousel-item {
  margin: 15px;
  display: grid;
  justify-content: center;
}

.payment-required {
  font-size: var(--font-size-text);
  font-weight: var(--font-weight-semibold);
}

.flippers {
  position: fixed;
  right: 10px;
  bottom: 10px;

  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 0px;
  align-content: center;

  background-color: var(--color-dark-grey);

  font-size: 12px;
  color: var(--color-white);
  font-weight: var(--font-weight-semibold);

  z-index: var(--z-index-3);
  margin-bottom: 10px;

  width: 120px;
  height: 40px;
  border-radius: var(--border-radius-32);

  opacity: 0.9;
}

.flpr-txt {
  display: grid;
  align-items: center;
  justify-content: center;

  height: 40px;
  width: 44px;
  border-radius: 32px;

  background-color: var(--color-black);
  opacity: 1;
}

.flipper img {
  height: 18px;
  opacity: 1;
}

.flipper {
  display: grid;
  justify-items: center;
  align-content: center;
  width: 40px;
  height: 40px;
}