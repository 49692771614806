.coming-soon-img {
  height: 45%
}

.coming-soon-cntnr {
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
  row-gap: 30px;
  font-size: var(--font-size-h5);
  font-weight: var(--font-weight-semibold);
}