.txns {}

.table {
    min-width: 600px;
    border: none;
    text-align: left;
    border-collapse: collapse;
}

.pymnt-hd {
    font-size: var(--font-size-h5);
    font-weight: var(--font-weight-semibold);
    margin-bottom: 20px;
}

.thead {
    font-weight: var(--font-weight-bold);
    color: var(--base-font-color-light);
}

.row {
    border-bottom: var(--border-bottom-line);
}

.cell img {
    width: 22px;
    padding-left: 10px;
}

.cell {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 4px;
}