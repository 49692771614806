.crs-name {
  overflow-wrap: break-word;
  border-top: var(--border-bottom-line);
  border-bottom: var(--border-bottom-line);
  margin-bottom: 12px;
  padding-bottom: 12px;
  padding-top: 12px;
  margin-left: 22px;
  margin-right: 22px;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-h5);
}

.crs-nav {
  grid-area: 'crs-nv';
  width: 300px;
  display: block;
  position: relative;
  height: 99%;
  overflow: auto;
  font-size: var(--font-size-text);
  background-color: var(--base-background-color);
  grid-column-end: -2;

  transition: width 0.1s, opacity 0.1s ease-in-out;
}

.crs-nav.hidden {
  width: 0px;
  opacity: 0;
}

.nav-tgl {
  height: 36px;
  width: 36px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 50%;
  display: grid;
  align-content: center;
  padding-inline-start: 10px;
  position: absolute;
  z-index: var(--z-index-5);
  left: 275px;
  transition: left 0.1s ease-in-out;
}

.nav-tgl.close {
  left: 10px;
  cursor: pointer;
}

.nav-tgl img {
  height: 18px;
}

.nav-tgl:hover img {
  height: 20px;
}