.signin-Rqrd-img {
  height: 30%
}

.signin-Rqrd-cntnr {
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
  row-gap: 30px;
  font-size: var(--font-size-h5);
  font-weight: var(--font-weight-semibold);
}

.signin-Rqrd-cntnr button {
  background: #5469d4;
  color: #ffffff;
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgb(0 0 0 / 7%);
  width: 80%;
}