  .account-page {
    display: grid;
    grid-template-columns: max-content 1fr;
    overflow-x: hidden;
    padding-top: 24px;
  }

  .account-nav {
    width: 200px;
  }

  .sectn-nm {
    border-radius: 4px;
    display: grid;
    align-items: center;
    height: 28px;
    padding-left: 6px;
    font-size: var(--font-size-text);
    font-weight: var(--font-weight-semibold);
  }

  .account-nav .selected {
    background-color: var(--base-theme-selected-highlight-blue-color);
  }

  .account-nav .notselected:hover {
    background-color: var(--base-theme-hover-highlight-blue-color);
    cursor: pointer;
  }

  .account-main {
    background-color: #FAF9F8;
    padding-top: 15px;
  }

  .account-main>div:first-child {
    width: 90%;
    margin: 0 auto;
  }