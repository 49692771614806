/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */

code[class*="language-"],
pre[class*="language-"] {
    color: var(--code-font-color);
    background: none;
    font-family: var(--code-font-family);
    font-size: var(--code-font-size);
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
    text-shadow: none;
    background: #b3d4fc;
}

pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
code[class*="language-"]::selection, code[class*="language-"] ::selection {
    text-shadow: none;
    background: #b3d4fc;
}

@media print {

    code[class*="language-"],
    pre[class*="language-"] {
        text-shadow: none;
    }
}

/* Code blocks */
pre[class*="language-"] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
}

:not(pre)>code[class*="language-"],
pre[class*="language-"] {
    background: var(--code-background-color);
    border: var(--code-border);
    border-radius: var(--border-radius-6);
}

/* Inline code */
:not(pre)>code[class*="language-"] {
    padding: .1em;
    border-radius: .3em;
    white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: var(--code-comment-color);
}

.token.punctuation {
    color: var(--code-punctuation-color);
}

.token.namespace {
    opacity: .7;
}

.token.boolean {
    color: var(--code-token-boolean-color);
    font-weight: var(--code-token-boolean-font-weight);
}

.token.property,
.token.tag,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
    color: var(--code-token-number-color);
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
    color: var(--code-char-string-color);
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
    color: var(--code-operator-color);
}

.token.atrule,
.token.attr-value,
.token.keyword {
    color: var(--code-keyword-color);
    font-weight: var(--code-token-keyword-font-weight);
}

.token.function,
.token.class-name {
    color: var(--code-function-color);
}

.token.regex,
.token.important,
.token.variable {
    color: var(--code-variable-color);
}

.token.important,
.token.bold {
    font-weight: bold;
}

.token.italic {
    font-style: italic;
}

.token.entity {
    cursor: help;
}