.mid-nav-hdr {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 24px;
}

.logo {
  height: 35px;
  padding-top: 10px;
}

.nav-hdr {
  width: 100%;
  padding: 0 20px;
  display: grid;
  grid-template-columns: auto 1fr minmax(32px, auto);
  justify-items: center;
  align-items: center;
  font-size: var(--font-size-text);
  font-weight: var(--font-weight-bold);
  color: var(--color-black)
}

.nav-hdr a:hover {
  color: var(--base-theme-color-hover-blue);
}

.signin-button {
  background-color: var(--base-theme-color-blue);
  border: 1px solid var(--base-theme-color-blue);
  color: var(--color-white);
  padding: 4px 20px;
  border-radius: var(--border-radius-32);
  cursor: pointer;
}

.signin-button:hover {
  background-color: var(--base-theme-color-hover-blue);
}